export var LIST_OF_PERSON_GROUPS = [
    "founder",
    "currentStaff",
    "formerStaff",
    "volunteer",
    "growthAdvisoryBoardMember",
    "guestAuthor",
    "advisor",
    "member",
    "boardMember", 
];
