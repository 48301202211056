import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _ts_generator from "@swc/helpers/src/_ts_generator.mjs";
import { client } from "./sanity.client";
export var sanityToken = process.env.SANITY_ACCESS_TOKEN;
var DEFAULT_PARAMS = {};
export function sanityFetch(_) {
    return _sanityFetch.apply(this, arguments);
}
function _sanityFetch() {
    _sanityFetch = _async_to_generator(function(param) {
        var preview, query, _params, params;
        return _ts_generator(this, function(_state) {
            preview = param.preview, query = param.query, _params = param.params, params = _params === void 0 ? DEFAULT_PARAMS : _params;
            if (preview && !sanityToken) {
                throw new Error("The `SANITY_ACCESS_TOKEN` environment variable is required.");
            }
            return [
                2,
                client.fetch(query, params, {
                    token: sanityToken,
                    perspective: preview ? "previewDrafts" : "published"
                })
            ];
        });
    });
    return _sanityFetch.apply(this, arguments);
}
