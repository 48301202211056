import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
/**
 * Helper function to return the correct version of the document
 * If we're in "preview mode" and have multiple documents, return the draft
 */ export var filterDocumentsToSingleItem = function(data, preview) {
    if (!Array.isArray(data)) {
        return data;
    }
    // fall back to the result with the longer locale (more exact result)
    data = data.sort(function(a, b) {
        var ref, ref1;
        return (((ref = b.locale) === null || ref === void 0 ? void 0 : ref.length) || 0) - (((ref1 = a.locale) === null || ref1 === void 0 ? void 0 : ref1.length) || 0);
    });
    if (data.length === 1) {
        return data[0];
    }
    if (preview) {
        return data.find(function(item) {
            return item._id.startsWith("drafts.");
        }) || data[0];
    }
    return data[0];
};
export function extractBreadcrumbs(page) {
    if (page.parentPage) {
        return [
            extractBreadcrumbs(page.parentPage),
            {
                _id: page._id,
                title: page.title || "untitled",
                path: page.path || "/"
            }, 
        ].flat();
    }
    return [
        {
            _id: page._id,
            title: page.title || "untitled",
            path: page.path || "/"
        }, 
    ];
}
export function blocksToText(blocks) {
    var opts = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    if (!blocks) return "";
    var defaults = {
        nonTextBehavior: "remove"
    };
    var options = Object.assign({}, defaults, opts);
    return blocks.map(function(block) {
        if (block._type !== "block" || !block.children) {
            return options.nonTextBehavior === "remove" ? "" : "[".concat(block._type, " block]");
        }
        return block.children.map(function(child) {
            return child.text;
        }).join("");
    }).join("\n\n");
}
export function isDocumentWithParentPage(entry) {
    return entry && typeof entry.parentPage !== "undefined";
}
export function isDocumentWithPath(entry) {
    return entry && typeof entry.path !== "undefined";
}
export function isLinkWithPath(entry) {
    return entry && typeof entry.path !== "undefined" && typeof entry.title !== "undefined";
}
export function isSanityRichText(entry) {
    return entry && Array.isArray(entry) && entry.some(function(e) {
        return e._type === "block";
    });
}
export var getArticleFromPage = function(page) {
    var ref;
    return (ref = page.pageBuilder) === null || ref === void 0 ? void 0 : ref.find(function(el) {
        return el._type == "article";
    });
};
export var getTitlesFromPage = function(page) {
    var ref;
    return (ref = page.pageBuilder) === null || ref === void 0 ? void 0 : ref.filter(function(el) {
        return el._type === "pageTitle";
    });
};
// Deep removes all null or undefined key value pairs (e.g. {test: null} -> {})
export var removeNull = function(object) {
    Object.entries(object).forEach(function(param) {
        var _param = _sliced_to_array(param, 2), k = _param[0], v = _param[1];
        if (v && typeof v === "object") removeNull(v);
        if (v === null || v === undefined) {
            delete object[k];
        }
    });
    return object;
};
